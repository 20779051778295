import * as React from 'react';
import { Helmet } from 'react-helmet';
import ContactUs from '../compontents/ContactUs/ContactUs';
import Layout from '../compontents/Layout/Layout';
import ProcessNew from '../compontents/ProcessNew/ProcessNew';

type ContactUsPageProps = {
    location: any;
};

const ContactUsPage: React.FC<ContactUsPageProps> = ({ location }) => {
    return (
        <div data-testid="contact-us-page">
            <Layout location={location}>
                <Helmet>
                    <title>Contact Us - Get Your Presence Online!</title>
                </Helmet>

                <div>
                    <ContactUs />
                </div>

                <div id="process" className="container my-5">
                    <h2 className="center">Get a Free Consultation and Analysis</h2>
                    <ProcessNew />
                </div>
            </Layout>
        </div>
    );
};

export default ContactUsPage;
