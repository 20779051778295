import { default as React, useEffect } from 'react';
import { container_background, phoneNumber, schedule } from './ContactUs.module.scss';

type HsbtpCreateArgumentType = {
    region: string;
    portalId: string;
    formId: string;
    target: string;
};

type HbsptType = {
    forms: {
        create: (options: HsbtpCreateArgumentType) => void;
    };
};

declare const hbspt: HbsptType;
const ContactUs: React.FC = () => {
    const TARGET_ID = 'hubspot-target';
    useEffect(() => {
        // First check if the script already exists on the dom
        // by searching for an id
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('id', id);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');

            // Append it to the head

            document.head.appendChild(script);

            // now wait for it to load...
            script.onload = () => {
                hbspt.forms.create({
                    region: 'na1',
                    portalId: '22505045',
                    formId: '034b68ed-df7e-492a-b9b4-f7531cd74fc4',
                    target: `#${TARGET_ID}`,
                });
            };
        }
    }, []);

    return (
        <div>
            <div
                className={`${container_background} contact-us-container d-flex flex-direction-row`}
                data-testid="contact-us-container">
                <div className="container">
                    <div className="row">
                        <div className="contact-us-text mt-5" data-testid="contact-us-text">
                            <h2>Contact us now to get your presence online or build your application</h2>
                        </div>
                        <span className={`${phoneNumber} mb-5`}>Phone: 801-309-1959</span>
                        {/* contact form */}
                        <div className="contact-us-form col-lg-6 mb-5" data-testid="contact-us-form">
                            <div id={TARGET_ID}></div>
                        </div>
                        {/* end contact form */}
                        {/* calendly link */}
                        <div className="col-lg-6 center mb-5 p-4">
                            <div className={schedule}>
                                <h3 className="center">Or Schedule a Chat</h3>
                                <p>Click here to schedule a short video chat with KC, the Director of Cloud Services</p>
                                <a
                                    className="button-primary"
                                    href="https://calendly.com/clearfinops/30min?month"
                                    target={'_blank'}
                                    rel="noreferrer">
                                    Schedule A Chat
                                </a>
                            </div>
                        </div>
                        {/* end calendly link */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
